.FilterButton {
    display: flex;
    width: 100%;
    border-radius: 0;
    border: none;
    background-color: transparent;
    padding: 12px 20px;
    color: #0D0D0D;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    text-align: left;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: none !important;
    outline: none;

    &--active {
        background: red;
    }
}
