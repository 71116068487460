.FilterTag {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 70%;
    margin-bottom: 10px;

    > span {
      margin-bottom: 2px;
    }

    &__Button {
        background: transparent;
        border: none;
        cursor: pointer;
    }
}
