@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Thin.eot');
	src: url('Gotham-Thin.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Thin.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 100;
	font-style: normal;
}
/* @font-face {
	font-family: 'Gotham';
	src: url('Gotham-XLight.eot');
	src: url('Gotham-XLight.eot?#iefix') format('embedded-opentype'),
		url('Gotham-XLight.woff') format('woff')/ *,
		url('Roboto-Bold.ttf') format('truetype')* /;
	font-weight: 200;
	font-style: normal;
} */
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Light.eot');
	src: url('Gotham-Light.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Light.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Book.eot');
	src: url('Gotham-Book.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Book.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Medium.eot');
	src: url('Gotham-Medium.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Medium.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Bold.eot');
	src: url('Gotham-Bold.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Bold.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Bold.eot');
	src: url('Gotham-Bold.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Bold.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Ultra.eot');
	src: url('Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Ultra.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url('Gotham-Black.eot');
	src: url('Gotham-Black.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Black.woff') format('woff')/*,
		url('Roboto-Bold.ttf') format('truetype')*/;
	font-weight: 900;
	font-style: normal;
}
