html,
body,
#root,
#app{
   padding: 0px;
   margin: 0px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   font-family: Arial;
   top: 0px;
   left: 0px;
   bottom: 0px;
   right: 0px;
}

*{
   box-sizing: border-box;
   -webkit-overflow-scrolling: touch;
}



/*da togliere*/
/*buttons*/
.btn{
   text-transform: uppercase;
   display: inline-block;
   padding: 14px 14px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
   font-size: 18px;
   text-decoration: none;
   text-align: center;
   letter-spacing: 1.5px;
   border: 2px solid black;
   -webkit-appearance: none;
   border-radius: 0;
   -webkit-font-smoothing: inherit;
   -moz-osx-font-smoothing: inherit;

}
.btn,
.btn:focus{
   outline: none;
}

.btn-100{
   width: 100%;
   display: block;
   padding: 14px 0px;
}

/*formats*/
h1{
   font-weight: 600;
   font-size: 22px;
   margin-bottom: 4px;
}
h2{
   font-weight: 600;
   font-size: 18px;
   margin-bottom: 4px;
}
h3{
   font-size: 15px;
}

p{
   font-size: 15px;
   line-height: 22px;
   margin: 15px 0px 0px;
}

a{
   color: inherit;
   text-decoration: none;
}

/*area tabs*/
#areatabs .area,
#areabar .area{
   color: #7B3572;
}


*{
  scrollbar-color: #0000004D transparent;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
   width: 6px;
}
::-webkit-scrollbar-track {
   background: transparent;
}
::-webkit-scrollbar-thumb {
   background: #0000004D;
}

*:active,*:focus {
  outline: none;
}

#vrf-account {
   --notification-bar-z-index: 9999;
}
