.Modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    justify-content: center;
    align-items: center;

    > div {
        z-index: 2;
    }

    &-enter {
        opacity: 0;
        visibility: hidden;
    }
    &-enter-done {
        opacity: 1;
        visibility: visible;
        //transition: opacity 300ms, transform 300ms;
    }
    &-exit {
        opacity: 1;
        visibility: visible;
    }
    &-exit-active {
        opacity: 0;
        //transition: opacity 300ms, transform 300ms;
        visibility: hidden;
    }

    &_Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(30,30,30,0.87);
        z-index: 1;
    }

    &_Inner {
        display: inline-block;
        text-align: right;
    }

    &_CloseButton {
        position: absolute;
        top: 30px;
        right: 30px;
        padding: 0;
        border: none;
        background: transparent;
        cursor: pointer;
    }

    &_Content {
        display: inline-block;
        padding: 30px;
        background: #fff;
        text-align: left;
    }

}
