.Filters {
    z-index: 9999;
    display: flex;
    height: 100%;
    transition: all 0.3s;

    &--show-subareas {
        transform: translateX(100%);
    }

    &__Panel {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        &Subareas {
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
        }
    }

    &__Header {
        /* flex-grow: 0;
        flex-shrink: 0; */
        padding: 12px 20px;
        height: 68px;

        &Main {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &Left,
            &Right {
                flex-basis: 50px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            &Center {
                flex-grow: 0;
                flex-shrink: 1;
                text-align: center;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
            }

            &Right {
                text-align: right;

                > * {
                    float: right;
                }
            }
        }

    }

    &__Footer {
        padding: 12px 20px;
    }

    &__Content {
        flex: 1;
        position: relative;
        overflow: hidden;
        display: flex;

        &Subareas {
            flex-direction: column;
        }
    }

    &__Levels {
        flex: 1;
        flex-wrap: nowrap;
        &Level {
            display: inline-flex;
        }
    }

    &__Items {
        /* border: 3px solid lime; */
        /* height: calc(100% - 76px); */
        height: 100%;
        position: relative;
        /* overflow: scroll; */
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__Item {
        position: relative;
        padding: 20px 20px;

        &Title {
            width: 100%;
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            color: inherit;
            text-align: left;
            cursor: pointer;

            &Text {
                flex-grow: 1;
                flex-shrink: 1;
                padding-right: 20px;
                letter-spacing: 1.5px;
                font-size: 14px;
            }

            &Icon {
                flex-grow: 0;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
            }
        }

        &Content {

        }

    }

    &__Tag {
        width: calc(100% + 40px);
        font-size: 11px;
        color: #0D0D0D;
        margin: 4px -20px 0px;
        padding: 5px 20px;
        text-transform: none;
        line-height: 18px;
        display: flex;

        &Text{
            flex-grow: 1;
            flex-shrink: 1;

            &Value {
                font-size: 12px;
                color: #ffde8d;
            }
        }

        &Remove {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 20px;
            height: 20px;
            display: none;

            background-image: url("/assets/icons/close-yellow.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top center;

            .Filters__Tag--active & {
                display: block;
            }
        }

        &--active {
            background-color: #00000033;
        }
    }

    &__Subgroups {

    }

    .List {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__Search {
            padding: 12px 10px;

            &Input {
                border-radius: 5px;
                background: rgba(#fff, 0.1);
                border: none;
                padding: 10px 10px;
                width: 100%;
                color: #0D0D0D;
                font-size: 14px;

                &::-webkit-input-placeholder {
                    color: #0D0D0D;
                }

                &:-moz-placeholder {
                    color: #0D0D0D;
                }

                &::-moz-placeholder {
                    color: #0D0D0D;
                }

                &:-ms-input-placeholder {
                    color: #0D0D0D;
                }

            }
        }

        &__Content {
            flex: 1;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}
